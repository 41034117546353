$gk-dark-blue: #002b59;
$gk-light-blue: #2a6496;
$gk-grey: #95a1af;
$gk-grey-2: #8b8d8d;
$gk-grey-3: #ebebeb;
$gk-light-grey: #d1d1d1;
$gk-darkest-grey: #58595b;
$gk-white: #fff;
$gk-red: #de1e1e;
$gk-red-2: #de1e1e;
$brand-primary: $gk-dark-blue;
$brand-secondary: $gk-dark-blue;
$brand-accent: $gk-grey;
$brand-white: $gk-white;
$brand-danger: $gk-red;
